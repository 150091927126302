const translations = {
  se: {
    activities: "aktiviteter",
    adventure: "äventyr",
    "budget-friendly": "budgetvänligt",
    sightseeing: "sightseeing",
    unusual: "ovanligt",
    beach: "strand",
    "famous cities": "berömda städer",
    "food&drink": "mat och dryck",
    "family fun": "kul för famlijer",
    nightlife: "nattliv",
    romance: "romans",
    surfing: "surfning",
    "city break": "storstadssemester",
    music: "musik",
    events: "event",
    culture: "kultur",
    "hidden gems": "dolda pärlor",
    mountains: "berg",
    sports: "sport",
    festivals: "festivaler",
    "diving&snorkeling": "dykning och snorkling",
    concerts: "konserter",
    "winter sport": "vintersport",
    island: "öar",
    skiing: "skidåkning",
    nature: "natur",
    lake: "sjöar",
    TURKEY: "Turkiet",
    "UNITED ARAB EMIRATES": "Förenade arabemiraten",
    SPAIN: "Spanien",
    PORTUGAL: "Portugal",
    MEXICO: "Mexiko",
    "UNITED STATES": "USA",
    NETHERLANDS: "Nederländerna",
    "UNITED KINGDOM": "Storbritannien",
    HUNGARY: "Ungern",
    FRANCE: "Frankrike",
    GREECE: "Grekland",
    AUSTRIA: "Österrike",
    GERMANY: "Tyskland",
    IRELAND: "Irland",
    CZECHIA: "Tjeckien",
    INDIA: "Indien",
    THAILAND: "Thailand",
    BRAZIL: "Brasilien",
    EGYPT: "Egypten",
    BELGIUM: "Belgien",
    DENMARK: "Danmark",
    MALDIVES: "Maldiverna",
    RUSSIA: "Ryssland",
    ITALY: "Italien",
    COLOMBIA: "Colombia",
    CYPRUS: "Cypern",
    MOROCCO: "Marocko",
    UKRAINE: "Ukraina",
    POLAND: "Polen",
    SWITZERLAND: "Schweiz",
    MALTA: "Malta",
    PHILIPPINES: "Filippinerna",
    PAKISTAN: "Pakistan",
    "DOMINICAN REPUBLIC": "Dominikanska republiken",
    PERU: "Peru",
    "COSTA RICA": "Costa Rica",
    INDONESIA: "Indonesien",
    NORWAY: "Norge",
    ISRAEL: "Israel",
    "UNKNOWN REGION": "Okänd region",
    CANADA: "Kanada",
    GEORGIA: "Georgia",
    "SOUTH KOREA": "Sydkorea",
    JAPAN: "Japan",
    ROMANIA: "Rumänien",
    "SOUTH AFRICA": "Sydafrika",
    ALGERIA: "Algeriet",
    "SRI LANKA": "Sri Lanka",
    CHILE: "Chile",
    FINLAND: "Finland",
    BULGARIA: "Bulgarien",
    JORDAN: "Jordan",
    MAURITIUS: "Mauritius",
    SERBIA: "Serbien",
    AUSTRALIA: "Australien",
    ARMENIA: "Armenien",
    ARGENTINA: "Argentina",
    SINGAPORE: "Singapore",
    TANZANIA: "Tanzania",
    TUNISIA: "Tunisien",
    LEBANON: "Libanon",
    ICELAND: "Island",
    ALBANIA: "Albanien",
    BANGLADESH: "Bangladesh",
    SWEDEN: "Sverige",
    NEPAL: "Nepal",
    URUGUAY: "Uruguay",
    LATVIA: "Lettland",
    "SAUDI ARABIA": "Saudiarabien",
    PANAMA: "Panama",
    CROATIA: "Kroatien",
    SEYCHELLES: "Seychellerna",
    GHANA: "Ghana",
    KENYA: "Kenya",
    NIGERIA: "Nigeria",
    LITHUANIA: "Litauen",
    "EL SALVADOR": "El Salvador",
    QATAR: "Qatar",
    ESTONIA: "Estland",
    MOLDOVA: "Moldavien",
    BELARUS: "Belarus",
    "HONG KONG": "Hong Kong",
    IRAN: "Iran",
    NICARAGUA: "Nicaragua",
    JAMAICA: "Jamaica",
    MALAYSIA: "Malaysia",
    GUATEMALA: "Guatemala",
    UZBEKISTAN: "Uzbekistan",
    OMAN: "Oman",
    KAZAKHSTAN: "Kazakstan",
    ECUADOR: "Ecuador",
    SENEGAL: "Senegal",
    LUXEMBOURG: "Luxemburg",
    ARUBA: "Aruba",
    MONTENEGRO: "Montenegro",
    CHINA: "Kina",
    BAHAMAS: "Bahamas",
    "NEW ZEALAND": "Nya Zeeland",
    KUWAIT: "Kuwait",
    TAIWAN: "Taiwan",
    "BOSNIA & HERZEGOVINA": "Bosnien och Hercegovina",
    VIETNAM: "Vietnam",
    KOSOVO: "Kosovo",
    CURAÇAO: "Curaçao",
    "REPUBLIC OF NORTH MACEDONIA": "Republiken Nordmakedonien",
    KYRGYZSTAN: "Kirgizistan",
    "FRENCH POLYNESIA": "Franska polynesien",
    BAHRAIN: "Bahrain",
    SLOVAKIA: "Slovakien",
    AZERBAIJAN: "Azerbajdzjan",
    "CAPE VERDE": "Cap Verde",
    BARBADOS: "Barbados",
    SLOVENIA: "Slovenien",
    IRAQ: "Irak",
    VENEZUELA: "Venezuela",
    PARAGUAY: "Paraguay",
    ETHIOPIA: "Etiopien",
    NAMIBIA: "Namibia",
    GIBRALTAR: "Gibraltar",
    "SINT MAARTEN": "Sint maarten",
    "CÔTE D’IVOIRE": "Elfenbenskusten",
    BOLIVIA: "Bolivia",
    GAMBIA: "Gambia",
    HONDURAS: "Honduras",
    UGANDA: "Uganda",
    CAMEROON: "Kamerun",
    BELIZE: "Belize",
    FIJI: "Fiji",
    MADAGASCAR: "Madagaskar",
    "TURKS & CAICOS ISLANDS": "Turks- och caicosöarna",
    "ST. LUCIA": "St. Lucia",
    SUDAN: "Sudan",
    TAJIKISTAN: "Tadzjikistan",
    "ANTIGUA & BARBUDA": "Antigua & barbuda",
    "TRINIDAD & TOBAGO": "Trinidad och Tobago",
    "FAROE ISLANDS": "Färöarna",
    CAMBODIA: "Kambodia",
    JERSEY: "Jersey",
    ZIMBABWE: "Zimbabwe",
    "DEMOCRATIC REPUBLIC OF THE CONGO": "Demokratiska republiken Kongo",
    ANGOLA: "Angola",
    RWANDA: "Rwanda",
    MALI: "Mali",
    TOGO: "Togo",
    GUYANA: "Guyana",
    HAITI: "Haiti",
    GUINEA: "Guinea",
    "SVALBARD & JAN MAYEN": "Svalbard & jan mayen",
    MOZAMBIQUE: "Moçambique",
    ZAMBIA: "Zambia",
    "CAYMAN ISLANDS": "Caymanöarna",
    AFGHANISTAN: "Afghanistan",
    BENIN: "Benin",
    DOMINICA: "Dominica",
    "SIERRA LEONE": "Sierra Leone",
    "BURKINA FASO": "Burkina Faso",
    SURINAME: "Surinam",
    "NEW CALEDONIA": "Nya Kaledonien",
    MACAU: "Macau",
    MAURITANIA: "Mauretanien",
    "MYANMAR (BURMA)": "Myanmar (burma)",
    "ISLE OF MAN": "Isle of man",
    SYRIA: "Syrien",
    SAMOA: "Samoa",
    COMOROS: "Komorerna",
    "SÃO TOMÉ & PRÍNCIPE": "São tomé & príncipe",
    GRENADA: "Grenada",
    GUERNSEY: "Guernsey",
    SOMALIA: "Somalia",
    "ST. BARTHÉLEMY": "St. Barthélemy",
    ERITREA: "Eritrea",
    GABON: "Gabon",
    LIBERIA: "Liberia",
    GREENLAND: "Grönland",
    DJIBOUTI: "Djibouti",
    LAOS: "Laos",
    "GUINEA-BISSAU": "Guinea-bissau",
    "ST. MARTIN": "St. Martin",
    MALAWI: "Malawi",
    "BRITISH VIRGIN ISLANDS": "Brittiska Jungfruöarna",
    NIGER: "Niger",
    "ST. VINCENT & GRENADINES": "St. Vincent & grenadinerna",
    BURUNDI: "Burundi",
    "REPUBLIC OF THE CONGO": "Republiken Kongo",
    BOTSWANA: "Botswana",
    "COOK ISLANDS": "Cooköarna",
    "ST. KITTS & NEVIS": "St. Kitts & Nevis",
    VANUATU: "Vanuatu",
    "PAPUA NEW GUINEA": "Papua Nya Guinea",
    CHAD: "Tchad",
    LIBYA: "Libyen",
    PALAU: "Palau",
    "TIMOR-LESTE": "Timor-leste",
    "EQUATORIAL GUINEA": "Ekvatorialguinea",
    BRUNEI: "Brunei",
    TURKMENISTAN: "Turkmenistan",
    "CENTRAL AFRICAN REPUBLIC": "Centralafrikanska republiken",
    "SOUTH SUDAN": "Södra Sudan",
    TONGA: "Tonga",
    ANGUILLA: "Anguilla",
    "SOLOMON ISLANDS": "Salomonöarna",
    "NORFOLK ISLAND": "Norfolksön",
    "ÅLAND ISLANDS": "Ålandsöarna",
    "COCOS (KEELING) ISLANDS": "Cocos (keeling) öar",
    KIRIBATI: "Kiribati",
    "ST. PIERRE & MIQUELON": "St. Pierre & miquelon",
    TUVALU: "Tuvalu",
    LESOTHO: "Lesotho",
    "MARSHALL ISLANDS": "Marshallöarna",
    YEMEN: "Jemen",
    NIUE: "Niue",
    MICRONESIA: "Mikronesia",
    "FALKLAND ISLANDS (ISLAS MALVINAS)": "Falklandsöarna (islas malvinas)",
    "WALLIS & FUTUNA": "Wallis & Futuna",
    "CHRISTMAS ISLAND": "Julön",
    ANDORRA: "Andorra",
    SWAZILAND: "Swaziland",
  },
}

export { translations }
