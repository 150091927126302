import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { FC, useEffect } from "react"
import { useState } from "react"
import { convertTimezone } from "../helpers/timezone"
import Countries from "../mappings/countries"
import { useStaticQuery, graphql, Link } from "gatsby"
//import Countries from "src/mappings/countries"
import { Helmet } from "react-helmet"
import { Grid } from "@mui/material"
import styled from "@emotion/styled"
import { translations } from "../../translations/translations"

// TODO make timezone actual timezone strings
interface PageContext {
  pageContext: {
    type: string
    cityName: string
    country: string
    timezone: string
  }
}

const MAX_ADDITIONAL_RECOMMENDATIONS = 10
const DAYS_FLIGHT_LIMIT = 31

const t: { [key: string]: string } = translations["se"]

const City: FC<PageContext> = ({ pageContext }) => {
  const { cityName, country, code, timezone, location, tags } = pageContext
  const [time, setTime] = useState(convertTimezone(new Date(), timezone))

  const { lat, lon } = location

  const translatedTags = tags.map(tag => t[tag] || tag)

  const translatedCountry = t[country] || country

  const allPagesResult = useStaticQuery(graphql`
    {
      allSitePage {
        edges {
          node {
            path
            pageContext
          }
        }
      }
    }
  `).allSitePage.edges

  const otherCountryPages = allPagesResult.filter(
    page =>
      !!page &&
      page.node.pageContext.country === country &&
      page.node.pageContext.cityName !== cityName
  )

  useEffect(() => {
    setInterval(() => {
      setTime(convertTimezone(new Date(), timezone))
    }, 1000)
  }, [])

  const now = new Date().toISOString().substring(0, 10)
  const end = new Date(Date.now() + 86400 * 1000 * DAYS_FLIGHT_LIMIT)
    .toISOString()
    .substring(0, 10)

  return (
    <Layout>
      <Helmet>
        <script
          data-width="100%"
          data-affilid="theflitiderwidget"
          data-from="?"
          data-to={code}
          data-limit="3"
          data-sort-by="price"
          data-results-only="true"
          src="https://widgets.kiwi.com/scripts/widget-search-iframe.js"
          data-departure={`${now}_${end}`}
        ></script>
      </Helmet>
      <Seo
        title={`Tiden just nu i ${cityName}`}
        lang="se"
        description={`Se vad tiden är i ${cityName} och se mer information om staden. Land: ${translatedCountry}`}
      />

      <h1 style={{ fontWeight: "200" }}>
        Tiden i <Bold>{cityName}</Bold> är{" "}
        <Bold>{time.toLocaleTimeString("sv-SE")}</Bold>
      </h1>

      <Grid container spacing={12}>
        <Grid item md={8} xs={12}>
          <iframe
            width="550"
            height="450"
            style={{
              border: "0",
              display: "block",
              width: "100%",
              maxWidth: "550px",
            }}
            loading="lazy"
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GOOGLE_MAPS_API_KEY}&q=${cityName},${country}&zoom=4`}
          />
          <Text>Datum: {time.toLocaleDateString("sv-SE")}</Text>
          <Text>Land: {translatedCountry}</Text>
          {/* TODO fix date format to be swedish */}
          <Text>Tidszon: {timezone}</Text>
          <Text>Longitud: {lon}</Text>
          <Text>Latitud: {lat}</Text>
          <br />
          <br />
          {!!otherCountryPages.length && (
            <>
              <h4>Se andra populära städer i {translatedCountry}:</h4>
              {otherCountryPages
                .slice(0, MAX_ADDITIONAL_RECOMMENDATIONS)
                .map(page => {
                  const { path, pageContext } = page.node
                  const { cityName } = pageContext

                  return (
                    <div key={path}>
                      <Link to={path}>{cityName}</Link>
                    </div>
                  )
                })}
            </>
          )}
          <br />
          <br />
          <br />
          {!!translatedTags.length > 1 && (
            <>
              <h4>Taggar för {cityName}:</h4>
              {translatedTags.map((tag, index) => {
                return <Text key={index}>{tag}</Text>
              })}
            </>
          )}
        </Grid>

        <br />

        <Grid item md={4} xs={12}>
          <Text
            style={{
              background: "#f5f7f9",
              marginBottom: 0,
              paddingTop: 12,
              paddingBottom: 0,
              paddingLeft: 12,
            }}
          >
            Flygresor till <Bold>{cityName}:</Bold>
          </Text>

          <div
            style={{ width: "100%", maxWidth: "576px" }}
            id="widget-holder"
          ></div>
        </Grid>
      </Grid>
      <br />
    </Layout>
  )
}

const Text = styled.p`
  margin-bottom: 2px;
`

const Bold = styled.span`
  font-weight: 700 !important;
`

export default City
